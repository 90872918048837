import React from "react";
import { TableRow, TableCell } from '@material-ui/core';

import './statisticsRecord.css';

const AgentStatisticsRecord = (props) => {
  const statistics = props.statistics;

  const toHHMMSS = (secs) => {
    let sec_num = parseInt(secs, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor(sec_num / 60) % 60
    let seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
  }

  function pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function formatDateTimeString(date) {
    if (date === null || date.replace(/^\s+/, '').replace(/\s+$/, '') === '') {
      return '';
    }

    let t = new Date(date);

    let hr = ("0" + t.getHours()).slice(-2);
    let min = ("0" + t.getMinutes()).slice(-2);
    let sec = ("0" + t.getSeconds()).slice(-2);

    return t.getFullYear() + "-" + pad(t.getMonth() + 1, 2) + "-" + pad(t.getDate(), 2) + " " + hr + ":" + min + ":" + sec;
  }

  function secondsOnStatus(date) {
    if (date === null || date.replace(/^\s+/, '').replace(/\s+$/, '') === '') {
      return null;
    }
    let d1 = new Date(date);
    let d2 = new Date();
    let seconds = Math.floor((d2.getTime() - d1.getTime())/1000);
    return seconds;
  };

  function getHeartbeatStatus(date) {
    let seconds = secondsOnStatus(date);

    if (seconds === null) {
      return '';
    }

    if (seconds < 10) {
      return 'alive';
    }

    return 'unresponsive';
  }

  if (statistics === null) {
    return <div></div>;
  }

  return (
    <TableRow className='agent-record-row'>
      <TableCell className='agent-record-column'>{statistics.login}</TableCell>
      <TableCell className='agent-record-column'>{statistics.lastName}, {statistics.firstName}</TableCell>
      <TableCell className='agent-record-column'>{statistics.routingProfile}</TableCell>
      <TableCell className={`agent-record-column ${statistics.currentStatus.replace(/ /g, '')}`}>{statistics.currentStatus}</TableCell>
      <TableCell className='agent-record-column'>{formatDateTimeString(statistics.statusTime)} ({toHHMMSS(secondsOnStatus(statistics.statusTime))})</TableCell>
      <TableCell className={`agent-record-column ${getHeartbeatStatus(statistics.heartbeatTime)}`}>{formatDateTimeString(statistics.heartbeatTime)}</TableCell>
      <TableCell className='agent-record-column'>{formatDateTimeString(statistics.idleStartTime)}</TableCell>
      <TableCell className='agent-record-column'>{formatDateTimeString(statistics.loginTime)}</TableCell>
      <TableCell className='agent-record-column'>{formatDateTimeString(statistics.logoutTime)}</TableCell>
    </TableRow>
  )
}

export default AgentStatisticsRecord;