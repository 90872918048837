import React from 'react';
import Typography from '@material-ui/core/Typography';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Typography component="div">{children}</Typography>
    </div>
  );
}

export default TabPanel;