import React, { Fragment } from "react";
import SockJsClient from 'react-stomp';
import { useAuth0 } from "../react-auth0-spa";
import Sidebar from 'react-sidebar';
import { Tabs, Tab } from '@material-ui/core';

import AgentStatisticsSection from "../components/AgentStatisticsSection";
import TabPanel from "../components/TabPanel";
import SidebarOptions from "../components/SidebarOptions";
import MeetingOverviewSection from "./MeetingOverviewSection";

const Profile = () => {
  const [ agentStatistics, setAgentStatistics ] = React.useState('');
  const [ meetingData, setMeetingData ] = React.useState('');
  const [ statisticsReady, setStatisticsReady ] = React.useState('');
  const [ meetingDataReady, setMeetingDataReady ] = React.useState('');
  const [ displayOptions, setDisplayOptions ] = React.useState({ hideOffline: false, hideIamUsers: true });
  const [ tab, setTab ] = React.useState('agent');
  const [ sidebarOpen, setSidebarOpen ] = React.useState(false);
  const { loading, user, getTokenSilently } = useAuth0();

  function updateAgentStatistics(data) {
    setAgentStatistics(data);
    setStatisticsReady(true);
  }

  function updateMeetingData(data) {
    setMeetingData(data);
    setMeetingDataReady(true);
  }

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  }

  if (loading || !user) {
    return <div></div>;
  }

  return (
    <Fragment>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab value="agent" label="User Statistics" {...a11yProps('agent')} />
        <Tab value="meeting" label="Video Meetings" {...a11yProps('meeting')} />
      </Tabs>
      <TabPanel value={tab} index="agent">
        {statisticsReady && <AgentStatisticsSection statisticsSection={agentStatistics} options={displayOptions} />}
      </TabPanel>
      <TabPanel value={tab} index="meeting">
        {meetingDataReady && <MeetingOverviewSection meetingData={meetingData} />}
      </TabPanel>
      {/* { statisticsReady && <AgentStatisticsSection statisticsSection={agentStatistics} />} */}
      {/* <Sidebar
        sidebar={<SidebarOptions displayOptions={ displayOptions, setDisplayOptions } />}
        open={sidebarOpen}
        onSetOpen={setSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
      >
        <div><button onClick={() => setSidebarOpen(true)}>Options</button></div>
      </Sidebar> */}
      <SockJsClient url={window.REACT_APP_SERVICE_URL + 'statistics'} topics={['/topic/statistics']}
        onMessage={(statistics) => { updateAgentStatistics(statistics); }} />
      <SockJsClient url={window.REACT_APP_SERVICE_URL + 'statistics'} topics={['/topic/meetings']}
        onMessage={(meetings) => { updateMeetingData(meetings); }} />
      {/* <button style={{display: 'none'}} onClick={() => refreshAgentStatistics()}>Force Refresh</button> */}
    </Fragment>
  );
};

export default Profile;