import React from "react";
import { Table, TableHead, TableBody, TableCell } from '@material-ui/core';
import {get} from 'lodash';

import MeetingRecord from './MeetingRecord';
import { TableRow } from "@material-ui/core";

const MeetingOverviewSection = (props) => {
  const meetingData = props.meetingData;

  if (meetingData === null) {
    return <div></div>;
  }

  let meetingRecords = [];

  for (var i=0; i<meetingData.length; i++) {
    meetingRecords.push(
      <MeetingRecord className='meeting-table' key={'meetingItem' + i} meeting={meetingData[i]}></MeetingRecord>
    );
  };
  meetingRecords.sort((a, b) => (get(a, 'props.meeting.requestDate', '') > get(b, 'props.meeting.requestDate', '')) ? 1 : -1)

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className='meeting-header-column'>ID</TableCell>
            <TableCell className='meeting-header-column'>Request Date</TableCell>
            <TableCell className='meeting-header-column'>Patient</TableCell>
            <TableCell className='meeting-header-column'>Assigned Nurse</TableCell>
            <TableCell className='meeting-header-column'>Mission Control</TableCell>
            <TableCell className='meeting-header-column'>Cluster</TableCell>
            <TableCell className='meeting-header-column'>Pod</TableCell>
            <TableCell className='meeting-header-column'>Offered to Nurse</TableCell>
            <TableCell className='meeting-header-column'>Offer Time</TableCell>
            <TableCell className='meeting-header-column'>Contacted Nurses</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meetingRecords}
        </TableBody>
      </Table>
    </div>
  );
}

export default MeetingOverviewSection;
