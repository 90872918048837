import React from "react";
import { TableRow, TableCell } from '@material-ui/core';

import './statisticsRecord.css';

const MeetingRecord = (props) => {
  const meeting = props.meeting;

  if (meeting === null) {
    return <div></div>;
  }

  return (
    <TableRow className='agent-record-row'>
      <TableCell className='meeting-column'>{meeting.meetingId}</TableCell>
      <TableCell className='meeting-column'>{meeting.requestDate}</TableCell>
      <TableCell className='meeting-column'>{meeting.patientId}</TableCell>
      <TableCell className='meeting-column'>{meeting.assignedNurse}</TableCell>
      <TableCell className='meeting-column'>{meeting.missionControlId}</TableCell>
      <TableCell className='meeting-column'>{meeting.clusterId}</TableCell>
      <TableCell className='meeting-column'>{meeting.podId}</TableCell>
      <TableCell className='meeting-column'>{meeting.currentlyOfferedNurse}</TableCell>
      <TableCell className='meeting-column'>{meeting.newNurseOfferTime}</TableCell>
      <TableCell className='meeting-column'>{meeting.contactedUserIds}</TableCell>
    </TableRow>
  )
}

export default MeetingRecord;