import React from "react";
import { Table, TableHead, TableBody, TableCell, TableSortLabel } from '@material-ui/core';
import {get} from 'lodash';

import AgentStatisticsRecord from './AgentStatisticsRecord';
import { TableRow } from "@material-ui/core";

function descendingComparator(a, b, orderBy) {
  if ((b[orderBy] || '') < (a[orderBy] || '')) {
    return -1;
  }
  if ((b[orderBy] || '') > (a[orderBy] || '')) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const AgentStatisticsSection = (props) => {
  const agentStatisticRecords = props.statisticsSection.agentStatusList;
  const hideIamUsers = get(props, 'options.hideIamUsers', false);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('login');

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  if (agentStatisticRecords === null) {
    return <div></div>;
  }

  let agentRecords = [];
  const sortedAgentStatisticRecords = stableSort(agentStatisticRecords, getComparator(order, orderBy));

  for (var i=0; i<sortedAgentStatisticRecords.length; i++) {
    if (hideIamUsers !== true || sortedAgentStatisticRecords[i].firstName !== 'IAM' || sortedAgentStatisticRecords[i].lastName !== 'IAM') {
      agentRecords.push(
        <AgentStatisticsRecord className='agent-record-table' key={'agentStatisticRecord' + i} statistics={sortedAgentStatisticRecords[i]}></AgentStatisticsRecord>
      );
    }
  };

  const headCells = [
    { id: 'login', label: 'Login' },
    { id: 'lastName', label: 'Name' },
    { id: 'routingProfile', label: 'Routing Profile' },
    { id: 'currentStatus', label: 'Status' },
    { id: 'statusTime', label: 'Time on Status' },
    { id: 'heartbeatTime', label: 'Last Heartbeat Time' },
    { id: 'idleStartTime', label: 'Idle Start Time' },
    { id: 'loginTime', label: 'Login Time' },
    { id: 'logoutTime', label: 'Logout Time' }
  ];

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {agentRecords}
        </TableBody>
      </Table>
    </div>
  );
}

export default AgentStatisticsSection;
